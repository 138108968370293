<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm hội đồng tuyển sinh' : 'Cập nhật hội đồng tuyển sinh'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên hội đồng tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên hội đồng tuyển sinh"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập hội đồng đợt tuyển sinh"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã hội đồng tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã hội đồng tuyển sinh"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã hội đồng tuyển sinh"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="admissionRound">
              <template v-slot:label>
                Đợt tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Ngày thành lập"
              label-for="establishDate"
            >
              <flat-pickr
                v-model="targetItem.establishDate"
                class="form-control"
                :config="config"
                placeholder="Ngày thành lập"
                name="establishDate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Số quyết định"
              label-for="establishDocNo"
            >
              <b-form-input
                id="establishDocNo"
                v-model="targetItem.establishDocNo"
                name="establishDocNo"
                placeholder="Nhập số quyết định"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import moment from 'moment/moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Save',
  components: {
    BRow,
    BFormDatepicker,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        admissionRoundId: null,
        establishDocNo: null,
        establishDate: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      config: {
        enableTime: false,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: true,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionCommittee/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization'
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createAdmissionCommittees: 'admissionCommittee/createAdmissionCommittees',
      updateAdmissionCommittees: 'admissionCommittee/updateAdmissionCommittees',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      if (this.item) {
        this.targetItem = { ...this.item }
        this.targetItem.establishDate = moment(this.targetItem.establishDate, 'DD/MM/YYYY').toDate()
      } else {
        this.targetItem = { ...this.targetItem, organizationId:getUser().orgId }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        admissionRoundId: null,
        establishDocNo: null,
        establishDate: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createAdmissionCommittees(this.targetItem)
            : await this.updateAdmissionCommittees(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
